import { AppstoreOutlined } from '@ant-design/icons';
import { Card, Col, List, Row, Tooltip, Typography } from 'antd';
import { useAuthentication } from 'hooks/useAuthentication';
import Cookies from 'js-cookie';
import { AcuityContext } from 'models/Application';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import blob_one from '../../assets/blob_one.svg';
import blob_two from '../../assets/blob_two.svg';
import { InlineStylesModel } from '../../models/InlineStylesModel';

const styles: InlineStylesModel = {
  card: {
    borderRadius: '4px',
    display: 'flex',
    pointerEvents: 'none',
    position: 'relative',
    flexFlow: 'column nowrap',
    width: '90%',
    height: '200px',
    marginBottom: '30px',
    textAlign: 'center',
    overflow: 'hidden',
    opacity: 0.9
  },
  cardLink: {
    display: 'flex',
    justifyContent: 'center'
  },
  icons: {
    fontSize: 50,
    color: 'rgb(89, 93, 110)',
    width: 50,
    borderRadius: 45
  },
  meta: {
    fontSize: '1em',
    width: '100%',
    marginBottom: 5,
    color: 'rgb(116, 120, 141)',
    zIndex: 999
  }
};

export const ApplicationCard = ({ application }: any): JSX.Element => {
  const customerCookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`) ?? '';

  const customer = customerCookie ? (JSON.parse(customerCookie) as { selectedCustomer: AcuityContext['selectedCustomer'] }) : undefined;

  /* ******************** Hooks ******************** */
  const { permissions } = useAuthentication();
  const { pathname } = useLocation();

  const isUrlInternal = pathname.includes('/internal-applications');

  /* ******************** Variables ******************** */

  const randomNumber = useMemo(() => Math.floor(Math.random() * 300), []);

  const hasAccessToApplication = permissions?.includes(application.accessPermissionName);
  const handleClick = (): void => {
    if (process.env.REACT_APP_COOKIE_PREFIX === 'localdev') {
      Cookies.set(`${process.env.REACT_APP_COOKIE_PREFIX}_internal`, JSON.stringify(isUrlInternal), { expires: 365 });
    } else {
      Cookies.set(`${process.env.REACT_APP_COOKIE_PREFIX}_internal`, JSON.stringify(isUrlInternal), { expires: 365, domain: 'mdsiinc.com' });
    }
  };

  /* ******************** Render ******************** */
  // if (hidePermissions && !hasAccessToApplication) return <div style={{ display: 'none' }}></div>;
  if (application.application) {
    const url = (): string | undefined => {
      if (!hasAccessToDivisionApplication) return undefined;
      else if (application.application.name !== 'Sales Order Inquiries') {
        return application.application.url;
      } else {
        return `${application.application.url}/${application.division.business.dataAreaId}/${application.division.erpId}`;
      }
    };

    const hasAccessToDivisionApplication = permissions?.includes(application.application.accessPermissionName);
    return (
      <Tooltip title={!hasAccessToDivisionApplication ? 'You do not have access to this application.' : null}>
        <a style={!hasAccessToDivisionApplication ? { cursor: 'default', opacity: 0.5 } : undefined} href={url()}>
          <List.Item colStyle={{ marginBottom: 20 }}>
            <Card
              onClick={handleClick}
              hoverable={hasAccessToDivisionApplication}
              bordered={false}
              style={{
                position: 'relative',
                height: 200,
                overflow: 'hidden',
                background: 'white',
                borderRadius: 5
              }}>
              <img
                alt="alt"
                src={blob_one}
                style={{
                  position: 'absolute',
                  right: '-208px',
                  top: '-79px',
                  height: '361px',
                  width: '432px',
                  zIndex: 99,
                  transform: `rotate(${randomNumber}deg)`
                }}
              />
              <img
                alt="alt"
                src={blob_two}
                style={{
                  position: 'absolute',
                  right: '-100px',
                  top: '-79px',
                  height: '361px',
                  width: '432px',
                  transform: `rotate(${randomNumber}deg)`
                }}
              />
              <Row>
                <Col span={14}>
                  <Typography.Title level={3} style={{ zIndex: 999, color: '#202020' }}>
                    {application.application.name}
                  </Typography.Title>
                </Col>
              </Row>
              <Row>
                <Col span={14} style={{ zIndex: 999 }}>
                  <Typography.Text style={{ zIndex: 999, color: '#202020' }}>{application.application.description}</Typography.Text>
                </Col>
              </Row>
              <Row style={{ zIndex: 999, position: 'absolute', bottom: 30, right: 30 }}>
                {application.application.logoUrl ? <img alt="" src={application.application.logoUrl} style={styles.icons} /> : <AppstoreOutlined style={styles.icons} />}
              </Row>
            </Card>
          </List.Item>
        </a>
      </Tooltip>
    );
  } else
    return (
      <Tooltip title={!hasAccessToApplication ? 'You do not have access to this application.' : null}>
        <a style={!hasAccessToApplication ? { cursor: 'default', opacity: 0.5 } : undefined} href={hasAccessToApplication ? application.url : null}>
          <List.Item colStyle={{ marginBottom: 20 }}>
            <Card
              onClick={handleClick}
              hoverable={hasAccessToApplication}
              bordered={false}
              style={{
                position: 'relative',
                height: 200,
                overflow: 'hidden',
                background: 'white',
                borderRadius: 5
              }}>
              <img
                alt="alt"
                src={blob_one}
                style={{
                  position: 'absolute',
                  right: '-208px',
                  top: '-79px',
                  height: '361px',
                  width: '432px',
                  zIndex: 99,
                  transform: `rotate(${randomNumber}deg)`
                }}
              />
              <img
                alt="alt"
                src={blob_two}
                style={{
                  position: 'absolute',
                  right: '-100px',
                  top: '-79px',
                  height: '361px',
                  width: '432px',
                  transform: `rotate(${randomNumber}deg)`
                }}
              />
              <Row>
                <Col span={14}>
                  <Typography.Title level={3} style={{ zIndex: 999, color: '#202020' }}>
                    {application.name}
                  </Typography.Title>
                </Col>
              </Row>
              <Row>
                <Col span={14} style={{ zIndex: 999 }}>
                  <Typography.Text style={{ zIndex: 999, color: '#202020' }}>{application.description}</Typography.Text>
                </Col>
              </Row>
              <Row style={{ zIndex: 999, position: 'absolute', bottom: 30, right: 30 }}>
                {application.logoUrl ? <img alt="" src={application.logoUrl} style={styles.icons} /> : <AppstoreOutlined style={styles.icons} />}
              </Row>
            </Card>
          </List.Item>
        </a>
      </Tooltip>
    );
};
