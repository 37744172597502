import { skipToken } from '@reduxjs/toolkit/dist/query';
import { List } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Loader } from 'components/common/Loader';
import { ApplicationCard } from 'components/molecules/ApplicationCard';
import Cookies from 'js-cookie';
import { AcuityContext } from 'models/Application';
import { useNavigate } from 'react-router-dom';
import { useGetApplicationsByDivisionQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';

const customerCookie = (): AcuityContext['selectedCustomer'] | undefined => {
  const customerCookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);

  if (customerCookie) {
    const { selectedCustomer } = JSON.parse(customerCookie) as AcuityContext;
    return selectedCustomer;
  }
};

export const DivisionApplicationsPage = (): JSX.Element => {
  const nav = useNavigate();

  const { data, isLoading, isFetching, isError, error } = useGetApplicationsByDivisionQuery(customerCookie()?.id ?? skipToken);
  // const { data, isLoading, isFetching } = useGetApplicationsQuery({ ...freddyCadabbyParams, isDivisionContextRequired: true });

  const { xs } = useBreakpoint();

  const filteredData = xs
    ? data?.data.filter((app) => app.application.isMobileOptimized).filter((app) => app.application.menuTab === 'Applications')
    : data?.data.filter((app) => app.application.menuTab === 'Applications');

  if (!customerCookie()) nav('/');

  return (
    <List
      style={{ marginTop: 16 }}
      grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
      loading={{
        indicator: <Loader />,
        size: 'large',
        spinning: isLoading || isFetching
      }}
      dataSource={filteredData ?? []}
      renderItem={(item): JSX.Element => <ApplicationCard application={item} />}
    />
  );
};
