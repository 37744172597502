import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Application, RudyApplicationModel } from 'models/Application';
import { Business } from 'models/Business';

export interface InitialState {
  inheritanceTypeFilter: ('Division' | 'Business' | 'BusinessWithDivisionOverride')[];
  customerSearch?: string;
  cachedBusinessList?: Business[];
  cachedDivisionApplicationList: { [key: string]: RudyApplicationModel[] };
  isTriggerLoading: boolean;
  isCustomersTriggerLoading: boolean;
  isDivAppsTriggerLoading: boolean;
  cachedInternalApplications?: Application[];
}

export const initialState: InitialState = {
  inheritanceTypeFilter: ['Business', 'Division', 'BusinessWithDivisionOverride'],
  customerSearch: undefined,
  cachedBusinessList: undefined,
  cachedDivisionApplicationList: {},
  cachedInternalApplications: undefined,
  isTriggerLoading: false,
  isCustomersTriggerLoading: false,
  isDivAppsTriggerLoading: false
};

export const businessListSlice = createSlice({
  name: 'businessList',
  initialState,
  reducers: {
    setInheritanceTypeFilter: (state, { payload }: PayloadAction<InitialState['inheritanceTypeFilter']>) => {
      state.inheritanceTypeFilter = payload;
    },
    setCustomerSearch: (state, { payload }: PayloadAction<InitialState['customerSearch']>) => {
      state.customerSearch = payload;
    },
    setCachedBusinessList: (state, { payload }: PayloadAction<InitialState['cachedBusinessList']>) => {
      state.cachedBusinessList = payload;
    },
    setCachedInternalApplicationList: (state, { payload }: PayloadAction<InitialState['cachedInternalApplications']>) => {
      state.cachedInternalApplications = payload;
    },
    setIsTriggerLoading: (state, { payload }: PayloadAction<InitialState['isTriggerLoading']>) => {
      state.isTriggerLoading = payload;
    },
    setIsCustomersTriggerLoading: (state, { payload }: PayloadAction<InitialState['isCustomersTriggerLoading']>) => {
      state.isCustomersTriggerLoading = payload;
    },
    setIsDivAppsTriggerLoading: (state, { payload }: PayloadAction<InitialState['isDivAppsTriggerLoading']>) => {
      state.isDivAppsTriggerLoading = payload;
    },
    putApplicationsForDivisions: (state, { payload }: PayloadAction<{ key: string; value: RudyApplicationModel[] }>) => {
      const { key, value } = payload;
      state.cachedDivisionApplicationList[key] = value;
    }
  }
});

export const {
  setInheritanceTypeFilter,
  setCustomerSearch,
  setCachedBusinessList,
  putApplicationsForDivisions,
  setIsTriggerLoading,
  setCachedInternalApplicationList,
  setIsCustomersTriggerLoading,
  setIsDivAppsTriggerLoading
} = businessListSlice.actions;
