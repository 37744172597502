import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Divider, Drawer, Row, Space } from 'antd';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useAppSelector } from 'redux/store';
import { MenuItem } from './MenuItem';

export const PhoneNavMenu = () => {
  const customerCookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);
  const { user } = useAppSelector((state) => state.app);

  const isMdsiEmployee = user && user['https://acuity.mdsiinc.com/user/user_metadata'].is_mdsi_employee;
  const [visible, setVisible] = useState(false);
  const items = [
    {
      text: 'Divisions',
      url: customerCookie ? '/divisions' : '/'
    },
    {
      text: 'Applications',
      url: '/divisions'
    },
    {
      text: 'Internal Applications',
      url: '/internal-applications'
    },
    {
      text: 'Warehouse Applications',
      url: '/warehouse-applications'
    },
    {
      text: 'Profile',
      url: '/profile'
    },
    {
      text: 'About',
      url: '/about'
    },
    {
      text: 'Log Out',
      url: '/logout'
    }
  ];

  const filteredItems = items.filter((item) => {
    if (!customerCookie) {
      return item.text !== 'Applications';
    }
    if (!isMdsiEmployee) {
      return item.text !== 'Warehouse Applications' && item.text !== 'Internal Applications';
    }
    return true;
  });
  return (
    <>
      <MenuOutlined style={{ fontSize: 25 }} onClick={() => setVisible(true)} />
      <Drawer
        title={
          <Row justify="end">
            <CloseOutlined style={{ fontSize: 25 }} onClick={() => setVisible(false)} />
          </Row>
        }
        destroyOnClose
        width={'80%'}
        open={visible}
        closeIcon={null}
        closable
        onClose={() => setVisible(false)}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {filteredItems.map((item) => (
            <>
              <MenuItem text={item.text} url={item.url} setVisible={setVisible} />
              <Divider style={{ margin: 5 }} />
            </>
          ))}
        </Space>
      </Drawer>
    </>
  );
};
