import { About } from 'pages/About';
import { DivisionApplicationsPage } from 'pages/DivisionApplications';
import { EditProfilePage } from 'pages/EditProfile';
import { HomePage } from 'pages/Home';
import { InternalApplicationsPage } from 'pages/InternalApplications';
import { WarehouseApplicationsPage } from 'pages/WarehouseApplications';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/internal-applications" element={<InternalApplicationsPage />} />
      <Route path="/warehouse-applications" element={<WarehouseApplicationsPage />} />
      <Route path="/divisions" element={<DivisionApplicationsPage />} />
    </Routes>
  );
};
