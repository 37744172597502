import { DoubleRightOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Row, Typography } from 'antd';
import Cookies from 'js-cookie';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
type Props = {
  text: string;
  url: string;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MenuItem: FC<Props> = ({ text, url, setVisible }) => {
  const { logout } = useAuth0();

  const handleRemoveCookies = () => {
    if (process.env.REACT_APP_COOKIE_PREFIX === 'localdev') {
      Cookies.remove(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);
    } else {
      Cookies.remove(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`, { path: '/', domain: 'mdsiinc.com' });
    }
  };

  const nav = useNavigate();

  if (text === 'Log Out')
    return (
      <Row
        justify="space-between"
        align="middle"
        style={{ width: '100%' }}
        onClick={() => {
          logout();
        }}>
        <Typography.Title style={{ padding: 0, margin: 0 }} level={4}>
          {text}
        </Typography.Title>
        <DoubleRightOutlined />
      </Row>
    );

  if (text === 'Profile')
    return (
      <a target="_blank" href={process.env.REACT_APP_PROFILE_URL} rel="noreferrer">
        <Row
          justify="space-between"
          align="middle"
          style={{ width: '100%' }}
          onClick={() => {
            setVisible(false);
          }}>
          <Typography.Title style={{ padding: 0, margin: 0 }} level={4}>
            {text}
          </Typography.Title>
          <DoubleRightOutlined />
        </Row>
      </a>
    );
  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ width: '100%' }}
      onClick={() => {
        if (text === 'Divisions') handleRemoveCookies();
        nav(url);
        setVisible(false);
      }}>
      <Typography.Title style={{ padding: 0, margin: 0 }} level={4}>
        {text}
      </Typography.Title>
      <DoubleRightOutlined />
    </Row>
  );
};
