import { Card, List, Typography } from 'antd';
import Cookies from 'js-cookie';
import { Business } from 'models/Business';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useNavigate } from 'react-router-dom';
import { useGetBusinessNameConatinsQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useAppSelector } from 'redux/store';

const { Paragraph } = Typography;

interface Props {
  division: Business['divisions'][number];
}
const styles: InlineStylesModel = {
  cardBody: {
    borderRadius: 10,
    padding: 0,
    height: '100%'
  },
  customerInfo: {
    padding: '10px 60px'
  },
  title: {
    fontSize: '0.75 em',
    margin: 0,
    color: '#2786fa'
  },
  values: {
    fontSize: '1 em',
    fontWeight: 600
  }
};
export const DivisionListItem = ({ division }: Props): JSX.Element => {
  const navigate = useNavigate();

  const { abbyCadabbyParams } = useAppSelector((state) => state);

  const { data } = useGetBusinessNameConatinsQuery(abbyCadabbyParams);

  /* ******************** Variables / Functions ******************** */

  const handleClick = (): void => {
    const parentBusiness = data?.data?.find((buss) => buss.divisions.find((div) => div.id === division.id));

    const selectedCustomer = {
      selectedCustomer: {
        business: {
          dataAreaId: parentBusiness?.dataAreaId,
          erpId: parentBusiness?.erpId,
          id: parentBusiness?.id,
          name: parentBusiness?.name
        },
        name: division?.name,
        erpId: division?.erpId,
        id: division?.id
      }
    };
    if (process.env.REACT_APP_COOKIE_PREFIX === 'localdev') {
      Cookies.set(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`, JSON.stringify(selectedCustomer), { expires: 365 });
    } else {
      Cookies.set(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`, JSON.stringify(selectedCustomer), { expires: 365, domain: 'mdsiinc.com' });
    }

    navigate(`/divisions`);
  };

  /* ******************** Renderer ******************** */
  return (
    <List.Item colStyle={{ height: '100%' }} style={{ height: '100%', marginBottom: 0 }}>
      <Card bodyStyle={{ padding: 10 }} hoverable style={{ height: '100%' }} onClick={handleClick}>
        <Paragraph style={styles.title} ellipsis={{ tooltip: `${division.name}` }}>
          {division.name}
        </Paragraph>
        <Paragraph style={{ marginBottom: 0 }}>{division.erpId}</Paragraph>
      </Card>
    </List.Item>
  );
};
