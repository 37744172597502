import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Drawer, Row } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCustomerSearch } from 'redux/slices/businessListSlice';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from './Inputs';

export const MobileFilters = (): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const handleCustomerSearchChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerSearch(event.target.value));
  }, 500);

  return (
    <>
      <Button icon={<FilterOutlined />} onClick={() => setVisible(true)} />
      <Drawer
        title={
          <Row justify="end">
            <CloseOutlined onClick={() => setVisible(false)} />
          </Row>
        }
        width={'80%'}
        height={'20%'}
        open={visible}
        placement="bottom"
        closeIcon={null}
        closable
        onClose={() => setVisible(false)}>
        <FancyInput placeholder="Search customer" onChange={handleCustomerSearchChange} />
      </Drawer>
    </>
  );
};
