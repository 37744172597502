import { FilterOutlined } from '@ant-design/icons';
import { Col, List, Row, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { FancyInput } from 'components/atoms/Inputs';
import { Loader } from 'components/common/Loader';
import { ApplicationCard } from 'components/molecules/ApplicationCard';
import { CustomDrawer } from 'components/molecules/CustomDrawer';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetApplicationsQuery } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { ReduxState } from 'redux/store';

export const InternalApplicationsPage = (): JSX.Element => {
  const { includeInactiveData } = useSelector((state: ReduxState) => state.freddyCadabbyParams);
  const [val, setVal] = useState('');
  const { data, isLoading, isFetching } = useGetApplicationsQuery({ isDivisionContextRequired: false, includeInactiveData });
  const { xs } = useBreakpoint();

  const handleSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    setVal(ev.target.value);
  };

  const filteredOptions = () => {
    if (!val) {
      if (xs) {
        return data?.data.filter((app) => app.isMobileOptimized).filter((app) => app.menuTab === 'Internal Applications');
      }
      return data?.data.filter((app) => app.menuTab === 'Internal Applications');
    }
    if (xs) {
      return data?.data?.filter((app) => app?.name.toLowerCase().includes(val.toLowerCase()) && app.isMobileOptimized).filter((app) => app.menuTab === 'Internal Applications');
    }
    return data?.data?.filter((app) => app?.name.toLowerCase().includes(val.toLowerCase())).filter((app) => app.menuTab === 'Internal Applications');
  };

  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Row justify="space-between">
          <Space>
            <Col>
              <AntPageTitle text="Internal Applications" />
            </Col>
            {!xs && (
              <Col>
                <FancyInput placeholder="Search applications" onChange={handleSearch} />
              </Col>
            )}
          </Space>
          {xs && (
            <CustomDrawer buttonProps={{ icon: <FilterOutlined /> }}>
              <FancyInput placeholder="Search applications" onChange={handleSearch} />
            </CustomDrawer>
          )}
        </Row>
        <List
          grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
          style={{ marginTop: 16 }}
          loading={{
            indicator: <Loader />,
            size: 'large',
            spinning: isLoading || isFetching
          }}
          dataSource={filteredOptions()}
          renderItem={(item): JSX.Element => <ApplicationCard application={item} />}
        />
      </div>
    </>
  );
};
