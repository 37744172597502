import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ApiMultipleResponse } from 'models/ApiModels';
import { Application, DivisionApplicationsPayload, RudyApplicationModel } from 'models/Application';
import { ProductCatalogConfigurationModel, ProductCatalogConfigurationsPayload } from 'models/RudyConfigurationModels';
import { ReduxState } from 'redux/store';
import { juliaApi } from '../julia';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_BASE_URL_RUDY_CADABBY;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_RUDY_CADABBY;

export const rudyCadabbyBaseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as ReduxState).app.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-functions-key', functionsKey);
      headers.set('Content-Type', 'application/json');
    }

    return headers;
  }
});

export const rudyCadabbyApi = createApi({
  reducerPath: 'rudyCadabbyApi',
  baseQuery: rudyCadabbyBaseQuery,
  tagTypes: ['CatalogConfiguration', 'CatalogConfigurations', 'DivisionApplications'],
  endpoints: (builder) => ({
    getDivisionApplications: builder.query<ApiMultipleResponse<DivisionApplicationsPayload>, string>({
      query: (applicationId) => ({
        url: `/applications/${applicationId}/divisions`
      }),
      providesTags: ['DivisionApplications']
    }),
    getApplicationsByDivision: builder.query<ApiMultipleResponse<RudyApplicationModel>, string>({
      query: (divisionId) => ({
        url: `/divisions/${divisionId}/applications`
      }),
      providesTags: ['DivisionApplications']
    }),
    deleteDivisionApplication: builder.mutation<Application, string>({
      query: (divisionApplicationId) => ({
        url: `/divisionApplications/${divisionApplicationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['DivisionApplications']
    }),
    createCatalogConfig: builder.mutation<string, { customerId: string; payload: ProductCatalogConfigurationsPayload }>({
      query: ({ customerId, payload }) => ({
        url: `/customers/${customerId}/applications/productCatalog/configuration`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['CatalogConfiguration', 'CatalogConfigurations'],
      onQueryStarted: async (_arg, _api) => {
        if (await _api.queryFulfilled) {
          _api.dispatch(juliaApi.util.invalidateTags(['ProductCatalogItems']));
        }
      }
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getCatalogConfig: builder.query<ProductCatalogConfigurationModel, { customerId: string }>({
      query: ({ customerId }) => ({
        url: `/customers/${customerId}/applications/productCatalog/configuration`
      }),
      providesTags: ['CatalogConfiguration']
    }),
    getCatalogConfigs: builder.query<ApiMultipleResponse<ProductCatalogConfigurationModel>, { dataInheritanceLevels: string }>({
      query: (params) => ({
        url: `/applications/productCatalog/configuration/customers`,
        params
      }),
      providesTags: ['CatalogConfigurations']
    })
  })
});

export const {
  useCreateCatalogConfigMutation,
  useGetVersionQuery,
  useGetApiNameQuery,
  useGetCatalogConfigQuery,
  useGetCatalogConfigsQuery,
  useGetDivisionApplicationsQuery,
  useDeleteDivisionApplicationMutation,
  useGetApplicationsByDivisionQuery,
  useLazyGetApplicationsByDivisionQuery
} = rudyCadabbyApi;
