import { Avatar, Button, List, Space, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import MdsiIcon from 'assets/mdsi-icon.svg';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { LegacyRef, MouseEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { useAppSelector } from 'redux/store';
import { InlineStylesModel } from '../../models/InlineStylesModel';
import { DivisionList } from './DivisionListItem';

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    paddingLeft: 10,
    borderRadius: 5
  },
  container: {
    alignItems: 'center'
  },
  title: {
    fontWeight: '100',
    marginBottom: 0
  },
  altTitle: {
    fontWeight: '100',
    color: '#18a799',
    marginBottom: 0
  }
};

export const BusinessListItem = ({ business }: any): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { customerSearch } = useAppSelector((state) => state.businessList);
  const [showDivision, _setShowDivisions] = useState(false);
  const { pathname } = useLocation();
  const [ref, { height }] = useMeasure();
  const { xs } = useBreakpoint();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showDivision ? height : 0
  });
  const isRecycleBinPage = pathname.includes('recycling-bin');

  /* ******************** Functions ******************** */
  const handleToggleShowDivision = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    _setShowDivisions((prevState) => !prevState);
  };

  // Making the divisions expand when something is searched in the filter

  useEffect(() => {
    if (customerSearch) {
      _setShowDivisions(true);
    }
    if (!business.divisions.length) _setShowDivisions(false);
  }, [business.divisions.length, customerSearch]);

  useEffect(() => {
    if (customerSearch === '') {
      _setShowDivisions(false);
    }
  }, [customerSearch]);

  // Render
  return (
    <>
      <List.Item onClick={business.divisions.length ? handleToggleShowDivision : undefined} style={business.divisions.length ? { ...styles.wrapper, ...{ cursor: 'pointer' } } : styles.wrapper}>
        <List.Item.Meta
          avatar={<Avatar src={business.logoUrl ? business.logoUrl : MdsiIcon} />}
          title={
            <Space>
              <Typography.Title level={5} style={styles.title}>
                {business.name}
              </Typography.Title>
              {!xs && (
                <>
                  <Typography.Title level={5} type="secondary" style={styles.title}>
                    |
                  </Typography.Title>
                  <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                    {business.erpId}
                  </Typography.Title>
                  <Typography.Title level={5} type="secondary" style={styles.title}>
                    |
                  </Typography.Title>
                  <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                    {isRecycleBinPage ? `${business.divisions.length} Deleted Divisions` : `${business.divisions.length} Divisions`}
                  </Typography.Title>
                </>
              )}
            </Space>
          }
          style={styles.container}
        />

        <Button
          disabled={!business.divisions.length}
          style={{ width: 110, marginRight: 8, padding: 0, border: 'none' }}
          onClick={handleToggleShowDivision}
          key="show-divisions"
          icon={<CaretSpinIcon isOpen={showDivision} />}>
          {showDivision ? 'Collapse' : 'Expand'}
        </Button>
      </List.Item>
      <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
        {showDivision && (
          <div ref={ref as LegacyRef<HTMLDivElement>}>
            <DivisionList divisions={business.divisions} />
          </div>
        )}
      </animated.div>
    </>
  );
};
