import Cookies from 'js-cookie';
import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  children: ReactElement;
};

export const ProtectedRoute: FC<Props> = ({ children }) => {
  const contextCookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);

  const hasCookie = contextCookie ? JSON.parse(contextCookie) : false;

  return !hasCookie ? children : <Navigate to="/divisions" replace />;
};
