import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const cookieCheck = () => {
  if (Cookies.get('hidePermissions')) {
    if (Cookies.get('hidePermissions') === 'true') return true;
    else return false;
  } else return false;
};

export interface InitialState {
  hidePermissions: boolean;
}

export const initialState: InitialState = {
  hidePermissions: cookieCheck()
};

export const togglePermissionsSlice = createSlice({
  name: 'hidePermissions',
  initialState,
  reducers: {
    togglePermissions: (state, { payload }: PayloadAction<InitialState['hidePermissions']>) => {
      Cookies.set(`hidePermissions`, payload.toString(), { expires: 365 });
      state.hidePermissions = payload;
    }
  }
});

export const { togglePermissions } = togglePermissionsSlice.actions;
