import { CloseOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Drawer, DrawerProps, Row } from 'antd';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = DrawerProps & {
  buttonProps?: ButtonProps;
  children: React.ReactNode;
};

export const CustomDrawer: FC<Props> = ({ buttonProps, children }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Button onClick={() => setVisible(true)} {...buttonProps} />
      <Drawer
        title={
          <Row justify="end">
            <CloseOutlined onClick={() => setVisible(false)} />
          </Row>
        }
        width={'80%'}
        height={'20%'}
        open={visible}
        placement="bottom"
        closeIcon={null}
        closable
        onClose={() => setVisible(false)}>
        {children}
      </Drawer>
    </>
  );
};
